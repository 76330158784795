/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        $(document).ready(function(){
          var browserH = $(window).height();
          var browserW = $(window).width();

          $(document).foundation();
          $(document).on("scroll", onScroll);
          $(".lazy").lazyload({
            effect : "fadeIn"
          });

          $('.btn_down').click(function(){
            $('body,html').animate({'scrollTop' : browserH-100}, 800);
          });

          $('ul.mainnav li a, .hidden_nav ul li a').click(function(e){
            e.preventDefault();
            var destination = $(this).attr('href');
            var pos = $(destination).offset();
            $('ul.mainnav li a').removeClass('active');
            $(this).addClass('active');
            $('body, html').animate({'scrollTop' : pos.top-100}, 800);
            $('.hidden_nav').fadeOut(500);
          });
          $('.logo_40x').click(function(){
            $('ul.mainnav li').eq(3).trigger('click');
          });

          $('.block, .noblock').click(function(){
            var thisRel = $(this).attr('rel');
            var thisSuite = "#suite_"+thisRel;
            $('.block').removeClass('active');
            $(this).addClass('active');
            $('.elevation_inner').fadeOut();
            $('.suite:visible').fadeOut();
            $(thisSuite).fadeIn();
            $('.floorplan_wrapper').animate({'opacity' : 1});
          });

          $('.btn_back').click(function(){
            $('.floorplan_wrapper').animate({'opacity' : 0});
            $('.elevation_inner').fadeIn();
          });

          $('ul.floorplan_nav li').click(function(){
            var thisRel = $(this).html();
            $(this).siblings('ul.floorplan_nav li').removeClass('active');
            $(this).addClass('active');
            if(thisRel == "core"){
              $(this).parents('.suite').find('img.core').fadeIn();
              $(this).parents('.suite').find('img.testfit').fadeOut();
              $(this).parents('.suite').find('.testfit_details').fadeOut();
            }else{
              $(this).parents('.suite').find('img.core').fadeOut();
              $(this).parents('.suite').find('img.testfit').fadeIn();
              $(this).parents('.suite').find('.testfit_details').fadeIn();
            }
          });
          $('ul.assets_nav li').click(function(){
            var thisRel = $(this).html();
            $(this).siblings('ul.assets_nav li').removeClass('active');
            $(this).addClass('active');
            if(thisRel == "floorplan"){
              $(this).parents('.suite').find('ul.floorplan_nav').fadeIn(function(){
                console.log('trigger click');
                $(this).parents('.suite').find('ul.floorplan_nav li').first().trigger('click');
              });
              $(this).parents('.suite').find('.video_holder').fadeOut();
              $(this).parents('.suite').find('.photo_holder').fadeOut();
            }else if(thisRel == "photography"){
              $(this).parents('.suite').find('ul.floorplan_nav, .testfit_details, img.floorplan, .testfit_details').fadeOut();
              $(this).parents('.suite').find('.video_holder').fadeOut();
              $(this).parents('.suite').find('.photo_holder').fadeIn();
            }else{
              $(this).parents('.suite').find('ul.floorplan_nav, .testfit_details, img.floorplan, .testfit_details').fadeOut();
              $(this).parents('.suite').find('.photo_holder').fadeOut();
              $(this).parents('.suite').find('.video_holder').fadeIn();
            }
          });

          $("#lightgallery").lightGallery();

          $('.btn_menu').click(function(){
            $('.hidden_nav').fadeIn(800);
            console.log('fadeIn');
          });

          $('.btn_close').click(function(){
            $('.hidden_nav').fadeOut(800);
            console.log('fadeOut');
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        $(document).ready(function(){
          var delay = 1500;
          var ani_speed = 0;
          var count = 0;
          setInterval(function(){
            $('img.slide').css({'opacity' : 0});
            count++;
            if(count < $('img.slide').length){
              $('img.slide').eq(count).animate({
                opacity:1
              }, ani_speed);
            }else if(count >= $('img.slide').length){
              //console.log('reset');
              count = 0
              //$('img.slide').css({'opacity' : 0});
              $('img.slide').eq(count).animate({
                opacity:1
              }, ani_speed);
            }
          }, delay);

          maps();
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
      
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  var stylesArray = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b",
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff",
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffe15f"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#efd151"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
  ];
  var map;
  var mapDefaultStyle = false;
  var latlng;
  var customOverlay;
  //Markers Array
  var allmarkers = [];
  //XML Variable
  var xmlUrl;
  var infoWindow = new google.maps.InfoWindow();
  var geocoder;
  var currentSection;
  //for map navigation
  var thisIndex;
  var browserW = $(window).width();
  var browserH = $(window).height();
  var navH;
  var thisCat;
  var html;
  var sitePath = "http://ifstudiony.com/sites/40x/wp-content/themes/40x/assets/";
  var markers_cat = $('ul.mapmarkers_hide li');
  var transitOverlay;

  function maps(){
    initialize();
    $('ul.mapnav li').eq(0).trigger('click');
    function initialize(){
        var zoom;
        if(browserW > 768){
          zoom = 16;
          latlng = new google.maps.LatLng(40.705747, -74.006200);
        }else{
          zoom = 15;
          latlng = new google.maps.LatLng(40.705910, -74.009290);
        }
        
        var minzoom;
        transitLayer = new google.maps.TransitLayer();
        
        var mapOptions = {
            center: latlng,
            zoom: zoom,
            maxZoom: 16,
            minZoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControl: false,
            zoomControl: true,
            streetViewControl: false,
            scaleControl: false,
            overviewMapControl: false,
            mapTypeControl: false,
            scrollwheel: false
        };

        map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

        // Add Map 
        if(!mapDefaultStyle)map.setOptions({styles: stylesArray});
        var iconBase = {
            url: sitePath + 'images/40x_logo_map.png',
            size: new google.maps.Size(120,164),
            scaledSize: new google.maps.Size(60,82),
            origin: new google.maps.Point(0,0),
            anchor: new google.maps.Point(60,82)
        };
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(40.705796, -74.010239),
            map: map,
            icon: iconBase,
            zIndex: 98
        });

        $('ul.mapnav li').click(function(){
          var thisID = $(this).html();

          $('ul.mapnav li').removeClass('active');
          $(this).addClass('active');
          clearMarkers();

          if(thisID == "amenities map"){
            //console.log('google maps in');
            $('#map-canvas, ul.markers').fadeIn();
            $('img.transit, .transit_details').fadeOut();

            transitLayer.setMap(null);
            markers_cat = $('ul.markers li');
            console.log(markers_cat.length);
            //console.log(markers_cat.length);

            allmarkers = allmarkers || [];
            for(var i = 0; i < markers_cat.length; i++){
              //console.log(markers_cat.length);
              var cat = markers_cat[i].getAttribute("data-cat");
              var name = markers_cat[i].getAttribute("data-name");
              var latitude = markers_cat[i].getAttribute("data-latitude");
              var longitude = markers_cat[i].getAttribute("data-longitude");
              var address = markers_cat[i].getAttribute("data-address");
              var website = markers_cat[i].getAttribute("data-website");
              var phone = markers_cat[i].getAttribute("data-phone");
              var image = markers_cat[i].getAttribute("data-image");
              var html;
              var markerpath;
              //console.log(i, name, latitude, longitude);
              if(image != ""){
                html = "<div class='infow'><div>" + name + "</div><img src='" + image + "'><br>" + address + "<br>" + phone + "<br>" + website +  "</div>";
              }else{
                html = "<div class='infow'><div>" + name + "</div>" + address + "<br>" + phone + "<br>" + website +  "</div>";
              }
              
              switch(cat){
                case "food":
                  markerpath = sitePath + "images/marker_food.png";
                break;
                case "restaurants":
                  markerpath = sitePath + "images/marker_restaurants.png";
                break;
                case "retail":
                  markerpath = sitePath + "images/marker_retail.png";
                break;
                case "entertainment":
                  markerpath = sitePath + "images/marker_entertainment.png";
                break;
                case "spa":
                  markerpath = sitePath + "images/marker_spa.png";
                break;
              }


              var markerimg = {
                url: markerpath,
                size: new google.maps.Size(24, 24),
                scaledSize: new google.maps.Size(12,12),
                origin: new google.maps.Point(0,0),
                anchor: new google.maps.Point(12, 12)
              };
              var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(latitude, longitude),
                  map: map,
                  icon: markerimg,
                  title: name,
                  zIndex: 99
              });
              allmarkers.push(marker);
              bindInfoWindow(marker, map, infoWindow, html);
            }
            $('.marker_title').click(function(){
              $('ul.cat').stop().slideUp();
              $(this).siblings('ul.cat').stop().slideDown();
            });
            $('.marker_title').eq(0).trigger('click');
          }else{
            $('#map-canvas, ul.markers').fadeOut();
            $('img.transit, .transit_details').fadeIn();
          }
        });
        
        $( ".markers li").click(function(){
          var thisIndex = $(this).index('.markers li');
          $('.markers li').removeClass('active');
          $(this).addClass('active');
          google.maps.event.trigger(allmarkers[thisIndex], "click");
        });
    }
    google.maps.event.addDomListener(window, 'load', initialize);
  }

  function bindInfoWindow(marker, map, infoWindow, html){
    google.maps.event.addListener(marker, 'click', function() {
        var infowindow = new google.maps.InfoWindow({
            content: html
        });
        //map.panTo(marker.getPosition());
        infoWindow.setContent(html);
        infoWindow.open(map, marker);
        //$('.markers div').removeClass('active');
    });
  }

  function clearMarkers(){
    //console.log(markers.length);
    for (var i = 0; i < allmarkers.length; i++) {
        //map.markers.pop().setMap(null);
        allmarkers[i].setMap(null);
        //console.log(i, allmarkers[i]);
    }
    //allmarkers = [];
    allmarkers.length = 0;
    //console.log(l,'clear markers');
  }

  function onScroll(event){
    var scrollPos = $(document).scrollTop();
    $('ul.mainnav li a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top-100 <= scrollPos && refElement.position().top-100 + refElement.height() > scrollPos) {
            $('ul.mainnav li a').removeClass("active");
            currLink.addClass("active");
        }
        else{
            currLink.removeClass("active");
        }
    });
  }

})(jQuery); // Fully reference jQuery after this point.
